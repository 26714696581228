<template>
  <div class="Complaint">
    <div class="top-title">
      <div class="top-text">投诉列表</div>
        <el-form ref="form" label-width="100px">
          <div class="top-box">
          <el-form-item label="评价等级" >
            <el-select v-model="seeks.a" placeholder="请选择"><el-option v-for="(r, i) in seek" :key="i" :label="r.label" :value="r.value"></el-option></el-select>
          </el-form-item>

              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="pingjia()"
                >搜索</el-button>
            </div>
        </el-form>
    </div>


    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="wk_name" label="投诉人"> </el-table-column>
        <el-table-column prop="bs_company_name" label="被投诉商家"> </el-table-column>
        <el-table-column prop="occurDatetime" label="投诉时间"> </el-table-column>
        <el-table-column prop="fromer_appraise" label="评价">
             <template slot-scope="scope">
                  <span><el-rate disabled v-model="scope.row.fromer_appraise"></el-rate></span>
            </template>
           </el-table-column>

        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"  type="text"
              >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,
      classactive:0,
      list:[],

      listpar:{},

      seeks:{},
      seek:[{
        label:'时间倒序',
        value:0,
      },{
        label:'评价等级从高到低',
        value:1,
      },{
        label:'评价等级从低到高',
        value:2,
      }]


    };
  },
  created() {
    this.getSong()
  },
  mounted() { },
  methods: {
    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,

        bizCatalog:'ComplaintEntity',
        handleMode:'fetchall',
        orderby:this.seeks.a,
        query:{
          status:this.classactive,
        }, })
        // console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },


    // 跳转详情页
    handleClick(row) {
      this.$router.push({ path: `/home/complaintpar/${row.id}` });
    },

        //弹窗
      handleClickRemove(row){
        this.listpar = row
          this.$confirm('是否确认结束', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.handleClickRemoves()
          }).catch(action => {
            console.log("取消");
          })
      },

    // 结束
  async handleClickRemoves() {
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

      bizCatalog:'ComplaintEntity',
      handleMode:'finishComplaintServicer',
      complaintId:this.listpar.id,
      bizData:{
        appraise_level:"2",
        appraise_remark:"算了结单吧444",
      },
       })
      //  console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('结束成功', '消息提示', {confirmButtonText: '确定'});
    },

    pingjia(){
       this.getSong();
    },

    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.Complaint .top-title{
  height: 70px;
}
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../../assets/css/liststyle.css";
</style>